import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { usePut } from "../../../Services/API/APIHandlers";
import { BASE_URL } from "../../../Services/Config";
import { SiteTransaction } from "../../../Services/Types/TransactionType";
import ImageWithImage from "../../components/ImagePreview/ImageWithImage";

const findUserField = (userId: any, obj: any) => {
  console.log(obj);
  for (const [key, value] of Object.entries(obj)) {
    if (value === userId) {
      return key; // Return the field name where userId is found
    }
  }
  return null; // Return null if userId is not found in any field
};

function mapUsersToRoleLevels(data: any) {
  const { users, roleLevels } = data;

  if (users.length !== roleLevels.length) {
    throw new Error(
      "Arrays `users` and `roleLevels` must have the same length"
    );
  }

  return roleLevels.reduce((acc: any, user: any, index: number) => {
    acc[user] = users[index];
    return acc;
  }, {});
}


function isVerifierIdPresent(
  transaction: SiteTransaction | undefined,
  verifierIdToCheck: string | null
): boolean {
  console.log(verifierIdToCheck, transaction);
  if (transaction && verifierIdToCheck)
    return transaction.siteVerificationLevels.some(
      (level) => level.verifierId === verifierIdToCheck
    );
  return false;
}

function SiteOrderVerification() {
  const { id } = useParams(); // Assuming the transaction ID is passed in the URL
  const [accessRoles, setAccessRoles] = useState<any>(null); // For storing fetched access roles
  const [transactionData, setTransactionData] = useState<SiteTransaction>();
  const [description, setDescriptionData] = useState<Array<any>>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const siteId = localStorage.getItem("siteId");
  const phone = localStorage.getItem("phone");
  const userid = localStorage.getItem("userid");
  const signatureUrl = localStorage.getItem("signatureUrl");

  const [thisUserPost, setThisUserPost] = useState<any>();

  const {
    mutate: signAndApprove,
    data: signAndApproveData,
    error: signAndApproveError,
    isSuccess: signAndApproveIsSuccess,
    isLoading: signAndApproveIsLoading,
  } = usePut(`api/transaction/${id}/verify/+91${phone}`);

  // Handle API error
  useEffect(() => {
    if (signAndApproveError instanceof AxiosError) {
      toast.error(signAndApproveError?.response?.data.message);
    }
  }, [signAndApproveError]);

  const fetchAccessRolesForSite = async (siteId: string | null) => {
    try {
      if (!siteId) return;
      const { data } = await axios.get(
        `${BASE_URL}api/access-management/segment/Sites/${siteId}`
      );

      console.log(data);

      setAccessRoles(mapUsersToRoleLevels(data));
    } catch (error) {}
  };

  console.log(transactionData);

  console.log(transactionData?.siteVerificationLevels.length);
  console.log(isVerifierIdPresent(transactionData, userid));

  useEffect(() => {
    if (accessRoles) {
      setThisUserPost(findUserField(userid, accessRoles));
    }
  }, [accessRoles]);

  // Set user data to state if successful
  useEffect(() => {
    if (signAndApproveIsSuccess && transactionData) {
      // setMembers(signAndApproveData.data);
    }
  }, [signAndApproveIsSuccess, signAndApproveData]);

  // Fetch transaction data based on the transaction ID
  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}api/transaction/site/${id}?siteId=${siteId}`
        );
        console.log(response.data);
        setTransactionData(response.data.transaction);
        setDescriptionData(response.data.description);
        fetchAccessRolesForSite(siteId);
        setLoading(false);
      } catch (err) {
        setError("Error fetching transaction data");
        setLoading(false);
      }
    };
    fetchTransactionData();
  }, [id]);

  console.log(description);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box p={5}>
      <Heading pb={4}>Order Verification</Heading>

      {/* Delivery Challan Information */}
      <VStack
        spacing={4}
        align="stretch"
        border="1px"
        borderColor="gray.200"
        p={5}
        borderRadius="md"
        shadow="md"
      >
        <Heading size="md">Delivery Challan Information</Heading>
        <Divider />
        <HStack justify="space-between">
          <Text fontWeight="bold">Challan No:</Text>
          <Text>
            {transactionData?.deliveryChallanData.delivery_challan_no}
          </Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">Date:</Text>
          <Text>{transactionData?.deliveryChallanData.date}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">Name:</Text>
          <Text>{transactionData?.deliveryChallanData.name}</Text>
        </HStack>
        <HStack justify="space-between">
          <Text fontWeight="bold">Address:</Text>
          <Text>{transactionData?.deliveryChallanData.address}</Text>
        </HStack>
      </VStack>

      {/* Challan Image Preview */}
      <Box mt={8} mb={8}>
        {transactionData?.challanLink ? (
          <ImageWithImage  imageUrl={transactionData?.challanLink} />
        ) : (
          <Text color="gray.500">No Challan Image Available</Text>
        )}
      </Box>

      {/* Items List */}
      <VStack
        spacing={4}
        align="stretch"
        border="1px"
        borderColor="gray.200"
        p={5}
        borderRadius="md"
        shadow="md"
      >
        <Heading size="md">Items List</Heading>
        <Divider />
        {description?.map((item: any, index: number) => (
          <HStack key={index} justify="space-between">
            <Text fontWeight="bold">
              {index + 1}: {item.item_name.value}{" "}
            </Text>
            <Text>{item.quantity} units</Text>
          </HStack>
        ))}
      </VStack>

      {/* Site Verification Levels */}
      <VStack
        spacing={4}
        align="stretch"
        border="1px"
        borderColor="gray.200"
        p={5}
        borderRadius="md"
        shadow="md"
        mt={8}
      >
        <Heading size="md">Site Verification Levels</Heading>
        <Divider />
        {transactionData?.siteVerificationLevels.map((verification, index) => (
          <HStack key={index} justify="space-between">
            <Text fontWeight="bold">
              Verifier {index + 1}: {verification.verifierPost}
            </Text>
            <Text>Status: {verification.status}</Text>
            <Text>
              Signature:{" "}
              {verification.signatureUrl ? (
                <Link
                  href={verification.signatureUrl}
                  isExternal
                  color="blue.500"
                >
                  View Signature
                </Link>
              ) : (
                "Not Available"
              )}
            </Text>
          </HStack>
        ))}
      </VStack>
      {transactionData &&
      transactionData?.siteVerificationLevels.length <= 3 &&
      !isVerifierIdPresent(transactionData, userid) ? (
        <VStack
          spacing={4}
          align="stretch"
          border="1px"
          borderColor="gray.200"
          p={5}
          borderRadius="md"
          shadow="md"
          mt={8}
        >
          <>
            <Button
              onClick={() => {
                // console.log({
                //   verifierId: userid,
                //   verifyingTime: Date.now,
                //   signatureUrl: signatureUrl,
                //   status: "approved",
                //   verifierPost: thisUserPost,
                // });
                signAndApprove({
                  verifierId: userid,
                  verifyingTime: Date.now,
                  signatureUrl: signatureUrl,
                  status: "approved",
                  verifierPost: thisUserPost,
                  challanImage: transactionData?.challanLink,
                });
              }}
              colorScheme="teal"
            >
              Approve
            </Button>
          </>
        </VStack>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default SiteOrderVerification;
