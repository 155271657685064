import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { usePost } from "../../../../../Services/API/APIHandlers";
import ImagePreviewModal from "../../../../components/ImagePreview";
import SiteSelect from "../../../../components/SitesSelect";
import OrderSlipSelect from "./../../../../components/OrderSlipSelect/index";

interface DeliveryChallanFormValues {
  delivery_challan_no: string;
  date: string;
  time: string;
  vehicle_no: string;
  driver_name: string;
  contact_no: string;
  from_project_name: string;
  from_site: string;
  to_project_name: string;
  to_site: string;
  order_slip_no: string; // New field for order slip selection
}

const initialValues: DeliveryChallanFormValues = {
  delivery_challan_no: "00001",
  date: new Date().toISOString().split("T")[0], // Set current date in YYYY-MM-DD format
  time: new Date().toTimeString().split(" ")[0].slice(0, 5), // Set current time in HH:MM format
  vehicle_no: "",
  driver_name: "",
  contact_no: "",
  from_project_name: "",
  from_site: "",
  to_project_name: "",
  to_site: "",
  order_slip_no: "", // Initialize order slip number
};

const validationSchema = Yup.object({
  delivery_challan_no: Yup.string().required("Delivery Challan No is required"),
  date: Yup.date().required("Date is required"),
  time: Yup.string().required("Time is required"),
  vehicle_no: Yup.string().required("Vehicle No is required"),
  driver_name: Yup.string().required("Driver Name is required"),
  contact_no: Yup.string().required("Contact No is required"),
  // from_project_name: Yup.string().required("From Project Name is required"),
  from_site: Yup.string().required("From Site is required"),
  // to_project_name: Yup.string().required("To Project Name is required"),
  to_site: Yup.string().required("To Site is required"),
  order_slip_no: Yup.string().required("Order Slip No is required"),
});

const texts = [
  {
    text: "NUMBER",
    size: 20,
    xCord: 1070,
    yCord: 144,
    style: "600",
    font: "Arial",
  },
  {
    text: "CHALLAN NO",
    size: 25,
    xCord: 1190,
    yCord: 190,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "Date",
    size: 25,
    xCord: 1050,
    yCord: 234,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "TIME",
    size: 25,
    xCord: 1270,
    yCord: 234,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "VEHICLE NO",
    size: 25,
    xCord: 1110,
    yCord: 276,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "DRIVERNAME",
    size: 25,
    xCord: 1120,
    yCord: 320,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "PHONE_NUMBER",
    size: 25,
    xCord: 1100,
    yCord: 366,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "FORM SITE",
    size: 25,
    xCord: 270,
    yCord: 492,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "FROM PROJECT NAME",
    size: 25,
    xCord: 210,
    yCord: 446,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "CHALLAN ID",
    size: 25,
    xCord: 850,
    yCord: 442,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "TO SITE",
    size: 25,
    xCord: 740,
    yCord: 489,
    style: "900",
    font: "sans-serif",
  },
  {
    text: "CHALLAN ID",
    size: 25,
    xCord: 800,
    yCord: 320,
    style: "900",
    font: "sans-serif",
  },
];

type pointerSet = {
  text: string;
  size: number;
  xCord: number;
  yCord: number;
  style: string;
  font: string;
};

const signatureImages = [
  {
    path: "https://res.cloudinary.com/dfhnhykia/image/upload/v1720084619/bpc/vnzialszybneymxl9m1t.png",
    xCord: 90,
    yCord: 1725,
  },
  {
    path: "https://res.cloudinary.com/dfhnhykia/image/upload/v1720084619/bpc/vnzialszybneymxl9m1t.png",
    xCord: 350,
    yCord: 1725,
  },
  {
    path: "https://res.cloudinary.com/dfhnhykia/image/upload/v1720084619/bpc/vnzialszybneymxl9m1t.png",
    xCord: 620,
    yCord: 1725,
  },
  {
    path: "https://res.cloudinary.com/dfhnhykia/image/upload/v1720084619/bpc/vnzialszybneymxl9m1t.png",
    xCord: 850,
    yCord: 1725,
  },
  {
    path: "https://res.cloudinary.com/dfhnhykia/image/upload/v1720084619/bpc/vnzialszybneymxl9m1t.png",
    xCord: 1200,
    yCord: 1725,
  },
];

type PropsType = {
  formData?: Array<Array<pointerSet>> | null;
  challanURL: string;
  setChallanUrl: React.Dispatch<React.SetStateAction<string>>;
  challanFormData?: any;
  setChallanFormData?: React.Dispatch<React.SetStateAction<any>>;
  submit?: boolean;
};

const DeliveryChallanForm = (props: PropsType) => {
  const [formDataGenerate, setFormDataGenerate] = useState<Array<pointerSet>>(
    []
  );

  const [itemsList, setItemList] = useState<Array<pointerSet> | null>(null);

  const formik = useFormik({
    initialValues,
    validationSchema,

    onSubmit: (values, actions) => {
      const formValuesArray = [
        "00001",
        "00001",
        values.date,
        values.time,
        // values.time,
        values.vehicle_no,
        values.driver_name,
        values.contact_no,
        values.from_project_name,
        values.from_site,
        values.to_project_name,
        values.to_site,
        "00001",
      ];

      if (props.setChallanFormData) props.setChallanFormData(formik.values);

      const generatedData = texts.map((textObj, index) => ({
        ...textObj,
        text: formValuesArray[index] || textObj.text,
      }));

      setFormDataGenerate(generatedData);

      mutate({
        texts: generatedData,
        signatureImages: signatureImages,
        itemData: props.formData,
        formInfo: formik.values,
      });

      // actions.setSubmitting(false);
    },
  });

  const { mutate, isLoading, error, data, isSuccess } = usePost(
    "api/generate-warehouse-challan"
  );

  useEffect(() => {
    if (error instanceof AxiosError) {
      // Call a toast
      toast.error(error?.response?.data.message);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      console.log();
      props.setChallanUrl(data.data.outputPath);
      toast.success("Generated Successfully");
    }
  }, [isSuccess, data]);

  useEffect(() => {
    formik.setFieldValue("date", new Date().toISOString().split("T")[0]);
    formik.setFieldValue(
      "time",
      new Date().toTimeString().split(" ")[0].slice(0, 5)
    );
  }, []);

  useEffect(() => {
    if (props.formData) {
      // setItemList(
      //   props.formData.map((item: formValuesTable) => generateObject(item))
      // );
    }
  }, [props.formData]);

  useEffect(() => {
    if (props.setChallanFormData) props.setChallanFormData(formik.values);
  }, [formik.values]);

  return (
    <Box p={4} mx="auto">
      <h1>Delivery Form</h1>
      <form onSubmit={formik.handleSubmit}>
        <SimpleGrid columns={[1, null, 2]} spacing={4}>
          <FormControl
            isInvalid={
              !!(
                formik.touched.delivery_challan_no &&
                formik.errors.delivery_challan_no
              )
            }
          >
            <FormLabel htmlFor="delivery_challan_no">
              Delivery Challan No
            </FormLabel>
            <Input
              isDisabled={true}
              id="delivery_challan_no"
              {...formik.getFieldProps("delivery_challan_no")}
              placeholder="Delivery Challan No"
            />
            <FormErrorMessage>
              {formik.errors.delivery_challan_no}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!(formik.touched.date && formik.errors.date)}
          >
            <FormLabel htmlFor="date">Date</FormLabel>
            <Input
              id="date"
              type="date"
              isDisabled={true}
              {...formik.getFieldProps("date")}
            />
            <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!(formik.touched.time && formik.errors.time)}
          >
            <FormLabel htmlFor="time">Time</FormLabel>
            <Input
              id="time"
              type="time"
              isDisabled={true}
              {...formik.getFieldProps("time")}
            />
            <FormErrorMessage>{formik.errors.time}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!(formik.touched.vehicle_no && formik.errors.vehicle_no)
            }
          >
            <FormLabel htmlFor="vehicle_no">Vehicle No</FormLabel>
            <Input
              id="vehicle_no"
              {...formik.getFieldProps("vehicle_no")}
              placeholder="Vehicle No"
            />
            <FormErrorMessage>{formik.errors.vehicle_no}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!(formik.touched.driver_name && formik.errors.driver_name)
            }
          >
            <FormLabel htmlFor="driver_name">Driver Name</FormLabel>
            <Input
              id="driver_name"
              {...formik.getFieldProps("driver_name")}
              placeholder="Driver Name"
            />
            <FormErrorMessage>{formik.errors.driver_name}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!(formik.touched.contact_no && formik.errors.contact_no)
            }
          >
            <FormLabel htmlFor="contact_no">Contact No</FormLabel>
            <Input
              id="contact_no"
              {...formik.getFieldProps("contact_no")}
              placeholder="Contact No"
            />
            <FormErrorMessage>{formik.errors.contact_no}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!(formik.touched.from_site && formik.errors.from_site)}
          >
            <FormLabel htmlFor="from_site">From</FormLabel>
            {/* <Input
              id="from_site"
              {...formik.getFieldProps("from_site")}
              placeholder="From Site"
            /> */}
            <SiteSelect
              disabled={true}
              onSiteChange={(site) => {
                formik.setFieldValue("from_site", site?.project_name);
                formik.setFieldValue("from_project_name", site?.address);
              }}
            />
            <FormErrorMessage>{formik.errors.from_site}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!(formik.touched.to_site && formik.errors.to_site)}
          >
            <FormLabel htmlFor="to_site">To</FormLabel>
            {/* <Input
              id="to_site"
              {...formik.getFieldProps("to_site")}
              placeholder="To Site"
            /> */}
            <SiteSelect
              disabled={true}
              onSiteChange={(site) => {
                formik.setFieldValue("to_site", site?.project_name);
                formik.setFieldValue("to_project_name", site?.address);
              }}
            />
            <FormErrorMessage>{formik.errors.to_site}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!formik.errors.order_slip_no}>
            <FormLabel htmlFor="order_slip_no">Order Slip No</FormLabel>
            <OrderSlipSelect // Adding OrderSlipSelect component
              {...formik.getFieldProps("order_slip_no")}
              onSelectChange={(value) =>
                formik.setFieldValue("order_slip_no", value)
              }
            />
            <FormErrorMessage>{formik.errors.order_slip_no}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        {/* {props.submit ? ( */}
        <Button mt={4} colorScheme="teal" isLoading={isLoading} type="submit">
          Generate
        </Button>
        {/* ) : (
          <></>
        )} */}
      </form>

      {props.challanURL && (
        <Box mt={8}>
          <h2>Preview Challan </h2>
          <br />
          <br />
          <ImagePreviewModal imageUrl={props.challanURL} />
        </Box>
      )}
    </Box>
  );
};

export default DeliveryChallanForm;
