import { Box, HStack, Input, InputGroup, Stack } from "@chakra-ui/react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

function Header(props: any) {
  return (
    <>
      <Box px={{ base: "4", md: "6" }} pt="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          <HStack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <h4 className="my-0">Sites</h4>
            {/* <div className="dropdown">
              <Link
                className="btn btn-outline-secondary fs-7 py-1 dropdown-toggle"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Create Sites
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <CreateUser />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </div> */}
          </HStack>
          {/* <InputGroup maxW="xs" className="justify-content-end">
            <IconButton
              icon={<IoIosList />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More"}
            />
            <IconButton
              icon={<IoSettingsOutline />}
              variant={"none"}
              className="text-dark fs-5"
              aria-label="Settings"
            />
            <div className="mx-3 border-end"></div>
            <IconButton
              icon={<BsArrowRepeat />}
              variant={"none"}
              aria-label="Refresh"
              className="text-dark fs-5"
            />
            <IconButton
              variant={"none"}
              className="text-dark fs-5"
              aria-label={"More Button"}
              icon={<HiOutlineDotsVertical />}
            />
            <IconButton
              aria-label="Additional Items"
              variant={"none"}
              className="text-dark fs-5"
              icon={<GoChevronDown />}
            />
          </InputGroup> */}
          {/* <InputGroup maxW="xs">
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="muted" boxSize="5" />
          </InputLeftElement>
          <Input placeholder="Search" />
        </InputGroup> */}
        </Stack>
      </Box>
      <hr />

      <Box px={{ base: "4", md: "6" }} pt="3" pb="5">
        <Stack
          direction={{ base: "column", md: "row" }}
          justify="space-between"
        >
          {/* <div className="d-flex align-items-center">
            <InputGroup maxW="xs" padding={0}>
              <Input
                placeholder="Search"
                size="sm"
                borderRadius={6}
                width={"auto"}
              />
            </InputGroup>
            <span className="ps-4 d-flex">
              <button className="btn text-dark p-1">
                <GoChevronLeft fontSize={18} />
              </button>
              <button className="btn text-dark p-1">
                <GoChevronRight fontSize={18} />
              </button>
            </span>
          </div> */}
        </Stack>
      </Box>
    </>
  );
}

export default Header;
