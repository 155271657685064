import {
  Box,
  HStack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { useGet } from "../../../../Services/API/APIHandlers";
import { transactionData } from "../../../../Services/Atoms/MaterialAtoms";
import UpdateTransaction from "../Forms/UserModal/OutwardTransactionData";
import ImagePreviewModal from "./../../../components/ImagePreview/index";
import Header from "./Header";

const formatStringWithBreaks = (str: string | null, length = 30) => {
  if (str) {
    const words = str.split(" ");
    let formattedString = "";
    let currentLine = "";

    words.forEach((word) => {
      // If adding the next word would exceed the line length
      if (currentLine.length + word.length + 1 > length) {
        formattedString += currentLine + "<br/>"; // Add current line with break
        currentLine = word; // Start a new line with the current word
      } else {
        // Append the word to the current line
        currentLine += (currentLine ? " " : "") + word;
      }
    });

    // Add the last line
    if (currentLine) {
      formattedString += currentLine;
    }

    return formattedString;
  } else return "";
};

interface CsvData {
  Items: string;
  "Challan Link": string;
  Type: string;

  Status: string;
  "Created At": string;
}

function formatDateToDDMMYY(dateString: string | undefined) {
  if (dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const year = String(date.getFullYear()).slice(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  }
}

export const InventoryTransactionTable = (props: TableProps) => {
  const [items, setItems] = useRecoilState(transactionData);

  const {
    mutate: getAllTransactions,
    isLoading,
    error: transactionDataError,
    data: transactionsData,
    isSuccess,
  } = useGet("api/materials/transactions");

  useEffect(() => {
    if (transactionDataError instanceof AxiosError) {
      toast.error(transactionDataError?.response?.data.message);
    }
  }, [transactionDataError]);

  useEffect(() => {
    if (isSuccess) {
      setItems(transactionsData.data);
    }
  }, [isSuccess, transactionsData]);

  useEffect(() => {
    getAllTransactions();
    window.scrollTo(0, 0);
  }, []);

  const csvFields = ["Items", "Challan Link", "Type", "Status", "Created At"];

  // Format the data for CSV export
  const csvData = items?.map((item) => ({
    Items: item.items,
    "Challan Link": item.challanLink,
    Type: item.type,
    Status: item.status,
    "Created At": item.createdAt,
  }));

  return (
    <>
      <Header csvData={csvData} csvFields={csvFields} />
      <Box
        className="mx-5 border-1"
        overflowY="auto"
        overflowX={"auto"}
        maxHeight="70vh"
      >
        <Table {...props}>
          <Thead position="sticky" top="0" zIndex="1" bg="gray.800">
            <Tr>
              {/* <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0">Transaction ID</Text>
                  </HStack>
                </HStack>
              </Th> */}
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Challan No.</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Items</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">From And To</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Challan Link</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Type</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Status</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>
                <HStack spacing="3">
                  <HStack spacing="1">
                    <Text className="mb-0 text-light">Created At</Text>
                  </HStack>
                </HStack>
              </Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody fontSize={"15px"}>
            {items &&
              items.map((item, index) => (
                <Tr key={index}>
                  {/* <Td>
                    <Text className="mb-0" color="muted">
                      {item._id.substring(0, 8)}
                    </Text>
                  </Td> */}
                  <Td>
                    <Text className="mb-0" color="muted">
                      00001
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: formatStringWithBreaks(item.items),
                        }}
                      />
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      WAREHOUSE TO T.Tangar
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="muted">
                      {item.challanLink && (
                        <ImagePreviewModal imageUrl={item.challanLink || ""} />
                      )}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.type}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {item.status}
                    </Text>
                  </Td>
                  <Td>
                    <Text className="mb-0" color="#777">
                      {formatDateToDDMMYY(item.createdAt)}
                    </Text>
                  </Td>
                  <Td>
                    <UpdateTransaction id={item._id} />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
