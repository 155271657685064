import { As, Button, ButtonProps, HStack, Icon, Text } from "@chakra-ui/react";

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
  head?: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, ...buttonProps } = props;

  return (
    <Button
      colorScheme="white"
      bg="white"
      color="black"
      variant={props.variant}
      justifyContent="start"
      // className={props.head ? "border rounded-0" : ""}
      {...buttonProps}
    >
      <HStack spacing="3" className="d-flex align-items-center">
        <Icon as={icon} margin={0} padding={0} boxSize="5" color="subtle" />
        <Text className="m-0">{label}</Text>
      </HStack>
    </Button>
  );
};
