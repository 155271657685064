import { Box } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { siteObject } from "../../../Services/Atoms/MaterialAtoms";
import { BASE_URL } from "../../../Services/Config";

interface Site {
  _id: string;
  siteId: string;
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date?: string;
}

interface SiteSelectProps {
  onSiteChange: (site: Site | null) => void;
  siteId?: String;
  exclude?: String;
  disabled?: boolean;
}

const SiteSelect: React.FC<SiteSelectProps> = ({
  onSiteChange,
  siteId,
  exclude,
  disabled,
}) => {
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSite, setSelectedSite] = useRecoilState(siteObject);

  useEffect(() => {
    if (siteId) {
      // Fetch sites from the API
      axios
        .get<Site>(BASE_URL + "api/fetch-site/" + siteId)
        .then((response) => {
          setSelectedSite(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching sites:", error);
        });
    }
  }, []);

  useEffect(() => {
    // Fetch sites from the API
    axios
      .get<Site[]>(BASE_URL + "api/fetch-all-sites")
      .then((response) => {
        setSites(
          response.data.filter((item) => {
            return item.project_name !== exclude;
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching sites:", error);
      });
  }, []);

  const handleSiteChange = (selectedOption: any) => {
    const site = sites.find((site) => site._id === selectedOption.value);
    setSelectedSite(site || null);
    onSiteChange(site || null); // Call the callback function with the selected site
  };

  return (
    <Box minWidth={100} zIndex={1000}>
      <Select
        options={sites.map((site) => ({
          value: site._id,
          label: site.project_name,
        }))}
        value={
          !disabled && selectedSite
            ? {
                value: selectedSite._id,
                label: selectedSite.project_name,
              }
            : undefined
        } // Use undefined instead of null
        styles={{
          control: (base, state) => ({
            ...base,
            borderRadius: 0,
            borderColor: "#ccc",
            color: "black",
          }),
          menu: (base) => ({
            ...base,
            borderRadius: 0,
          }),
          option: (base, state) => ({
            ...base,
            color: "black",
            backgroundColor: state.isSelected ? "#f5f5f5" : "white",
            ":hover": {
              backgroundColor: "#f0f0f0",
            },
          }),
          singleValue: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        onChange={handleSiteChange}
      />
    </Box>
  );
};

export default SiteSelect;
