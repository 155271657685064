import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useGet } from "../../../Services/API/APIHandlers";
import { BASE_URL } from "../../../Services/Config";
import { UserType } from "../../../Services/Types/UserTypes";
import SiteSelect from "../../components/SitesSelect";

interface Site {
  _id: string;
  siteId: string;
  project_name: string;
  project_id: string;
  address: string;
  department: string;
  start_date: string;
  end_date?: string;
}

const SiteAccess = () => {
  const [members, setMembers] = useState<UserType[]>([]);
  const [accessRoles, setAccessRoles] = useState<any>(null); // For storing fetched access roles
  const [siteId, setSiteId] = useState<string | null>(null); // State to hold the selected site ID

  // Fetch users data
  const {
    mutate: getUsers,
    isLoading,
    error: userDataError,
    data: usersData,
    isSuccess,
  } = useGet("api/users/");

  // Handle API error
  useEffect(() => {
    if (userDataError instanceof AxiosError) {
      toast.error(userDataError?.response?.data.message);
    }
  }, [userDataError]);

  // Set user data to state if successful
  useEffect(() => {
    if (isSuccess) {
      setMembers(usersData.data);
    }
  }, [isSuccess, usersData]);

  // Fetch access roles for selected site
  const fetchAccessRolesForSite = async (siteId: string) => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}api/access-management/segment/Sites/${siteId}`
      );
      formik.setValues({
        storeInCharge: data.users[0] || "",
        siteInCharge: data.users[1] || "",
        siteAccountant: data.users[2] || "",
        projectManager: data.users[3] || "",
        receivingInCharge: data.users[4] || "",
      });
      setAccessRoles(data);
    } catch (error) {
      formik.setValues({
        storeInCharge: "",
        siteInCharge: "",
        siteAccountant: "",
        projectManager: "",
        receivingInCharge: "",
      });
    }
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      storeInCharge: "",
      siteInCharge: "",
      siteAccountant: "",
      projectManager: "",
      receivingInCharge: "",
    },
    enableReinitialize: true, // Allow the form to update with fetched accessRoles data
    validationSchema: Yup.object({
      storeInCharge: Yup.string().required("Store In-Charge is required"),
      siteInCharge: Yup.string().required("Site In-Charge is required"),
      siteAccountant: Yup.string().required("Site Accountant is required"),
      projectManager: Yup.string().required("Project Manager is required"),
      receivingInCharge: Yup.string().required(
        "Receiving In-Charge is required"
      ),
    }),
    onSubmit: async (values) => {
      const dataToSubmit = {
        levels: 5, // Total fields for users
        siteId, // Include the selected site ID
        users: [
          values.storeInCharge,
          values.siteInCharge,
          values.siteAccountant,
          values.projectManager,
          values.receivingInCharge,
        ],
        roleLevels: [
          "storeInCharge",
          "siteInCharge",
          "siteAccountant",
          "projectManager",
          "receivingInCharge",
        ],
        signatureUrls: ["", "", "", "", ""], // Set signatureUrls to empty strings by default
      };

      try {
        await axios.post(
          `${BASE_URL}api/access-management/segment/Sites`,
          dataToSubmit
        );
        toast.success("Access management entry created successfully!");
      } catch (error) {
        toast.error("Error submitting the form");
      }
    },
  });

  // Handle site selection
  const handleSiteChange = (site: Site | null) => {
    const selectedSiteId = site ? site.siteId : null;
    setSiteId(selectedSiteId); // Set the site ID or null if no site is selected

    if (selectedSiteId) {
      // Fetch roles for the selected site and exclude "WAREHOUSE"
      fetchAccessRolesForSite(selectedSiteId);
    }
  };

  // Fetch users when component mounts
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Form onSubmit={formik.handleSubmit}>
      {/* Include SiteSelect component */}
      <SiteSelect onSiteChange={handleSiteChange} exclude="WAREHOUSE" />

      <Container>
        <Row>
          <Col md={6}>
            <Form.Group controlId="storeInCharge">
              <Form.Label>Store In-Charge</Form.Label>
              <Form.Control
                as="select"
                name="storeInCharge"
                value={formik.values.storeInCharge}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.storeInCharge && formik.touched.storeInCharge
                }
              >
                <option value="">Select Store In-Charge</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.storeInCharge}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="siteInCharge">
              <Form.Label>Site In-Charge</Form.Label>
              <Form.Control
                as="select"
                name="siteInCharge"
                value={formik.values.siteInCharge}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.siteInCharge && formik.touched.siteInCharge
                }
              >
                <option value="">Select Site In-Charge</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.siteInCharge}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Additional Fields */}
        <Row>
          <Col md={6}>
            <Form.Group controlId="siteAccountant">
              <Form.Label>Site Accountant</Form.Label>
              <Form.Control
                as="select"
                name="siteAccountant"
                value={formik.values.siteAccountant}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.siteAccountant &&
                  formik.touched.siteAccountant
                }
              >
                <option value="">Select Site Accountant</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.siteAccountant}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="projectManager">
              <Form.Label>Project Manager</Form.Label>
              <Form.Control
                as="select"
                name="projectManager"
                value={formik.values.projectManager}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.projectManager &&
                  formik.touched.projectManager
                }
              >
                <option value="">Select Project Manager</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.projectManager}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group controlId="receivingInCharge">
              <Form.Label>Receiving In-Charge</Form.Label>
              <Form.Control
                as="select"
                name="receivingInCharge"
                value={formik.values.receivingInCharge}
                onChange={formik.handleChange}
                isInvalid={
                  !!formik.errors.receivingInCharge &&
                  formik.touched.receivingInCharge
                }
              >
                <option value="">Select Receiving In-Charge</option>
                {members.map((user) => (
                  <option key={user._id} value={user._id}>
                    {user.first_name + " " + user.last_name}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.errors.receivingInCharge}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Button type="submit" className="mt-3" disabled={isLoading}>
        {isLoading ? "Updating..." : "Update"}
      </Button>
    </Form>
  );
};

export default SiteAccess;
